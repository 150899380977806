import styled from 'styled-components';

export const Wrapper = styled.main`
  @font-face {
    font-family: tgico;
    src: url(/static/fonts/tgico.ttf)
    format("truetype"),
    url(/static/fonts/tgico.woff)
    format("woff"),
    url(/static/fonts/tgico.svg)
    format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: block
  }
  
  height: 100%;
`;
