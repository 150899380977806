import { ComponentPropsWithoutRef, FC } from 'react';

import * as UI from './ui';

type TgicoProps = {
    inline?: boolean;
    type:
    | 'add'
    | 'mic'
    | 'volume'
    | 'gift'
    | 'cross'
    | 'play'
    | 'pause'
    | 'next'
    | 'previous'
    | 'rewindBack'
    | 'rewindForward'
    | 'down'
    | 'person'
    | 'share'
    | 'star'
    | 'hand'
    | 'pin'
    | `${number}x`
    | '0.5x'
    | '1x'
    | '1.5x'
    | '2x'
    size: number;
};

// see /static/fonts/tgico.ttf on https://fontdrop.info/
const getSymbol = (type: string): string => {
    switch (type) {
        case 'add':
            return '\uE9B9';
        case 'mic':
            return '\uE991';
        case 'volume':
            return '\uE9F5';
        case 'gift':
            return '\uE966';
        case 'cross':
            return '\uE937';
        case 'play':
            return '';
        case 'pause':
            return '';
        case 'next':
            return '';
        case 'previous':
            return '';
        case 'down':
            return '\uE94A';
        case 'rewindBack':
            return '\uE9E2';
        case 'rewindForward':
            return '\uE9E3';
        case 'person':
            return '\uE9A2';
        case 'share':
            return '\uE98E';
        case 'star':
            return '\uE9FB';
        case 'hand':
            return '\uE9CB';
        case 'pin':
            return '\uE92C';
        case '0.5x':
            return '';
        case '1x':
            return '';
        case '1.5x':
            return '';
        case '2x':
            return '';
        default:
            return '';
    }
};

const Tgico: FC<TgicoProps & ComponentPropsWithoutRef<'div'>> = ({ inline, type, size, onClick }) => {
    return (
        <UI.Wrapper
            $inline={inline}
            size={size}
            onClick={onClick}
        >
            <UI.Inner>{getSymbol(type)}</UI.Inner>
        </UI.Wrapper>
    );
};

export default Tgico;
