import { Avatar } from '@telegram-apps/telegram-ui';
import { FC, useCallback } from 'react';

import Cell from '@/shared/components/cell';

import * as UI from './ui';

type MiniPlayerProps = {
    title: string;
    description: string;
    image: string;
    playing: boolean;
    onClick: VoidFunction;
    onTogglePlay: VoidFunction;
    onNextClick: VoidFunction;
}
const MiniPlayer: FC<MiniPlayerProps> = ({
    title,
    description,
    playing,
    image,
    onClick,
    onTogglePlay,
    onNextClick,
}) => {
    const handlePlayClick = useCallback((evt: { stopPropagation: () => void; }) => {
        evt.stopPropagation();

        onTogglePlay();
    }, []);

    const handleNextClick = useCallback((evt: { stopPropagation: () => void; }) => {
        evt.stopPropagation();

        onNextClick();
    }, []);

    return (
        <UI.Outer>
            <UI.Wrapper>
                <Cell
                    flat
                    key={title}
                    before={
                        <Avatar
                            size={48}
                            src={image}
                        />
                    }
                    head={<UI.Description>{description}</UI.Description>}
                    body={<UI.Title>{title}</UI.Title>}
                    after={
                        <>
                            <UI.PlayButton onClick={handlePlayClick}>
                                {
                                    playing ? (
                                        <svg
                                            width='36'
                                            height='36'
                                            viewBox='0 0 36 36'
                                            fill='none'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <circle
                                                cx='18'
                                                cy='18'
                                                r='18'
                                                fill='white'
                                            />

                                            <rect
                                                x='8.85693'
                                                y='7.71387'
                                                width='6.85714'
                                                height='20.5714'
                                                rx='2'
                                                fill='#333333'
                                            />

                                            <rect
                                                x='20.2856'
                                                y='7.71387'
                                                width='6.85714'
                                                height='20.5714'
                                                rx='2'
                                                fill='#333333'
                                            />
                                        </svg>


                                    ) : (
                                        <svg
                                            width='36'
                                            height='36'
                                            viewBox='0 0 36 36'
                                            fill='none'
                                            xmlns='http://www.w3.org/2000/svg'
                                        >
                                            <circle
                                                cx='18'
                                                cy='18'
                                                r='18'
                                                fill='white'
                                            />

                                            <path
                                                d='M12.0485 9.3363V26.2534C12.0485 26.3216 12.0389 26.7613 12.0932 26.8615C12.3238 27.2872 12.9914 27.8532 14.1818 27.281C15.3759 26.707 22.8524 22.2206 27.1697 19.6093C27.7164 19.2787 28.0478 18.6931 28.0478 18.0616C28.0478 17.4201 27.7061 16.827 27.1459 16.4988C22.825 13.9679 15.3737 9.58754 14.1818 8.8236C12.9018 8.00327 12.2263 8.8236 12.0485 9.3363Z'
                                                fill='#333333'
                                            />
                                        </svg>
                                    )
                                }
                            </UI.PlayButton>

                            <UI.NextButton onClick={handleNextClick}>
                                <svg
                                    width='36'
                                    height='36'
                                    viewBox='0 0 36 36'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <circle
                                        cx='18'
                                        cy='18'
                                        r='18'
                                        fill='white'
                                    />

                                    <path
                                        d='M9.59664 9.937C9.21158 9.65249 8.66666 9.92739 8.66666 10.4062V25.5935C8.66666 26.0723 9.2116 26.3472 9.59666 26.0627L19.8738 18.4686C20.1894 18.2355 20.1893 17.7635 19.8738 17.5303L9.59664 9.937Z'
                                        fill='#333333'
                                    />

                                    <path
                                        d='M24.5921 9.83321C24.27 9.83321 24.0088 10.0944 24.0088 10.4165V25.5832C24.0088 25.9054 24.27 26.1665 24.5921 26.1665H26.3421C26.6643 26.1665 26.9255 25.9054 26.9255 25.5832V10.4165C26.9255 10.0944 26.6643 9.83321 26.3421 9.83321H24.5921Z'
                                        fill='#333333'
                                    />
                                </svg>
                            </UI.NextButton>

                        </>
                    }
                    onClick={onClick}
                />
            </UI.Wrapper>
        </UI.Outer>
    );
};

export default MiniPlayer;

