import styled from 'styled-components';

export const PageContent = styled.div`
  padding-bottom: 64px;
  background-color: ${props => props.theme.colors.backgroundCatalogue};
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

