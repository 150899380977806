import styled, { css } from 'styled-components';

import { TextMedium15, TextRegular14 } from '@/shared/typography';

export const Outer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 0;
`;

export const Wrapper = styled.div`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  width: 100%;
  background-color: #333333;

   padding: 12px;
   ${props => props.theme.isIos() && css`
     padding-bottom: 28px;
   `}

  box-shadow: 1px -2px 16px 1px rgba(0, 0, 0, .1);
`;


export const Title = styled.div`
   ${TextMedium15};
   color: ${props => props.theme.colors.playerControls};
`;

export const Description = styled.div`
   ${TextRegular14};
   color: ${props => props.theme.colors.playerControls};
`;

export const PlayButton = styled.div`
  padding-left: 8px;
`;

export const NextButton = styled.div`
  padding-left: 8px;
`;
