import { DefaultTheme } from 'styled-components';

declare module 'styled-components' {
    export interface DefaultTheme {
        colors: {
            primaryButtonsLinks: string;
            textPrimary: string;
            textSecondary: string;
            backgroundCatalogue: string;
            backgroundHeader: string;
            backgroundChannelPage: string;
            postcastBlocksBg: string;
            searchBar: string;
            iconsBorders: string;
            playerControls: string;
        },
        zIndex: {
            under: number;
            start: number;
            header: number;
            popup: number;
        };
        isIos: () => boolean;
        isAndroid: () => boolean;
    }
}

const zIndex = {
    under: -1,
    start: 1,
    header: 2,
    popup: 3,
};

export const defaultTheme: DefaultTheme = {
    colors: {
        primaryButtonsLinks: '#037ee5',
        textPrimary: '#1c1c1c',
        textSecondary: '#838383',
        backgroundCatalogue: '#ffffff',
        backgroundHeader: '#ffffff',
        backgroundChannelPage: '#efeff4',
        postcastBlocksBg: '#ffffff',
        searchBar: '#e7e7e8',
        iconsBorders: '#b7b7b7',
        playerControls: '#ffffff'
    },
    zIndex,
    isIos: () => window.Telegram.WebApp.platform === 'ios',
    isAndroid: () => window.Telegram.WebApp.platform === 'android',
};
