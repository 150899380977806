const RECENTS_KEY = 'recents';

class StorageService {

    private getValue = <T = Record<string, any>>(key: string): T => {
        return JSON.parse(window.localStorage.getItem(key) || '{}');
    };

    private setValue = <T = Record<string, any>>(key: string, value: T) => {
        return window.localStorage.setItem(key, JSON.stringify(value));
    };

    public addRecent = (channel: string) => {
        const data = this.getValue(RECENTS_KEY);
        if (!data.recents) {
            data.recents = [];
        }

        const found = data.recents.indexOf(channel);
        if (found !== -1) {
            data.recents.splice(found, 1);
        }

        data.recents.push(channel);

        this.setValue(RECENTS_KEY, data);
    };

    public getRecents = (): string[] => {
        const data = this.getValue(RECENTS_KEY);

        return data.recents || [];
    };
}

export default StorageService;
