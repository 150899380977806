import EventEmitter from '@/lib/subscriber';
import ConfigurationService from '@/services/configuration-service';
import LogsService from '@/services/logs-service';

export type Channel = {
    score: number;
    link: string;
    username: string;
    title: string;
    description: string;
    photo: string;
    total: number;
};

export type Post = {
    id: number;
    title: string;
    content: string;
    link: string;
    image: string;
    novoice: boolean;
    createdAtOrigin: number;
    audio: Record<string, {
        filename: string;
        duration: number;
    }>;

}
export type Settings = {
    lastPlaylist?: string;
    lastPostId?: number
    time?: number;
    selectedVoice?: string;
    playbackRate?: number;
}

export type InitData = {
    user: {
        id: number;
        limitExceed: boolean;
    };
    premium: {
        price: number;
        durationSec: number;
        strikedPrice: number;
    },
    channelToOpen: {
        playlistToPlay: string;
        playImmediately: boolean;
        showChannelPage: boolean;
        posts: Post[];
        channel: Channel;
        currentPost: number;
        selectedVoice: string;
        time: number;
        playbackRate: number;
    },
    postsMap: Record<string, Post[]>;
    channelsMap: Record<string, Channel>;
    folderNames: string[];
    folderToShow: string;
    folders: Record<string, Channel[]>;
    settings: {
        paginationPlaylistPageSize: number;
        playlistPreloadOffset: number;
        telegramBotPublicName: string;
        telegramBotAppName: string;
        maxSecPerDayLimit: number;
    }
}

class PostCastService extends EventEmitter<{
    'onInit': InitData;
}>{
    private logsService: LogsService;
    private configurationService: ConfigurationService;

    constructor(
        logsService: LogsService,
        configurationService: ConfigurationService,
    ) {
        super();

        this.logsService = logsService;
        this.configurationService = configurationService;
    }

    private request = async <T>(arm: string, payload?: any): Promise<T> => {
        let status = null;
        let data: any = {};

        const options: RequestInit = {
            headers: {
                'authorization': window.Telegram?.WebApp.initData,
                'Content-Type': 'application/json',
            }
        };

        if (payload) {
            options.method = 'POST';
            // options.headers['Content-Type'] = 'application/json';
            options.body = JSON.stringify(payload);
        }

        const url = this.configurationService.getPostCastHost();
        try {
            const res = await fetch(url + arm, options);
            status = res.status;
            data = await res.json();
        } catch (e) {
            this.logsService.captureMessage(`${arm} request failed `);
        }

        if (status === 200) {
            return data;
        }

        if (status === 401) {
            throw new Error('Unath');
        }

        if (status === 500) {
            throw new Error('Common exception');
        }

        throw new Error('Common exception');
    };

    public onInit = (callback: (userInfo: InitData) => void): void => {
        this.addEventListener('onInit', callback);
    };

    public getInitData = async (requiredChannel?: string, postToPlay?: number) => {
        const response = await this.request<InitData>('/api/init', {
            requiredChannel,
            postToPlay,
        });

        const postsMap = {
            [response.channelToOpen.playlistToPlay]: response.channelToOpen.posts,
        };

        const channelsMap = {
            [response.channelToOpen.playlistToPlay]: response.channelToOpen.channel,
        };

        const folderNames: string[] = [];

        Object.entries(response.folders).forEach(([folderName, channels]) => {
            folderNames.push(folderName);

            channels.forEach(channel => {
                channelsMap[channel.username] = channel;
            });
        });

        this.dispatch('onInit', {
            user: response.user,
            premium: response.premium ?? { price: 0, durationSec : 0, strikedPrice: 0 },
            channelsMap,
            postsMap,
            channelToOpen: response.channelToOpen,
            folderNames,
            folderToShow: response.folderToShow,
            folders: response.folders,
            settings: response.settings,
        });
    };

    public moreChannels = async (
        pagination: number,
        folder: string,
    ): Promise<{
        channels: Channel[],
        channelsMap: Record<string, Channel>
        isOver: boolean,
    }> => {
        const { channels, isOver } = await this.request<{
            channels: Channel[],
            isOver: boolean
        }>('/api/more-channels', {
            pagination,
            folder,
        });

        return {
            isOver,
            channels,
            // todo dry
            channelsMap: channels.reduce<Record<string, Channel>>((map, channel) => {
                map[channel.username] = channel;

                return map;
            }, {}),
        };
    };

    public search = async (value: string): Promise<Channel[]> => {
        const { channels } = await this.request<{ channels: Channel[] }>('/api/search', { value });

        return channels;
    };

    public morePosts = async (playlist: string, pagination: number): Promise<Post[]> => {
        const { posts } = await this.request<{ posts: Post[] }>('/api/more-posts', {
            playlist,
            pagination,
        });

        return posts;
    };

    public updateSettings = async (settings: Settings) => {
        return this.request<{ limitExceed: boolean }>('/api/update-settings', settings);
    };

    public postListened = async (postId: number) => {
        return this.request<void>('/api/post-listened', {
            postId,
        });
    };

    public getInvoice = async (title: string, description: string) => {
        return this.request<{ invoiceLink: string }>('/api/get-invoice', {
            title,
            description,
        });
    };

    public createNotifications = async (channelsValue: string) => {
        return this.request<void>('/api/create-notifications', {
            channelsValue
        });
    };

    public event = async (data: { eventName: string, payload?: Record<string, any> }): Promise<void> => {
        const { eventName, payload } = data;

        return this.request<void>('/api/event', {
            eventName,
            payload,
        });
    };
}

export default PostCastService;
