import { Modal } from '@telegram-apps/telegram-ui';
import { FC, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import PostCastService from '@/services/post-cast-service';
import Tgico from '@/shared/icons/tgico';

import * as UI from './ui';

type PaywallProps = {
    price: number;
    strikedPrice: number;
    durationSec: number;
    postCastService: PostCastService;
    maxSecPerDayLimit: number
}

const Paywall: FC<PaywallProps> = ({
    price,
    strikedPrice,
    durationSec,
    maxSecPerDayLimit,
    postCastService
}) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(true);

    const handlePay = useCallback(async () => {
        Telegram.WebApp.MainButton.disable();

        const { invoiceLink } = await postCastService.getInvoice(
            t('premium_subscription.title'),
            t('premium_subscription.description'),
        );
        Telegram.WebApp.openInvoice(invoiceLink, () => {
            window.location.reload();
        });
    }, []);

    useEffect(() => {
        Telegram.WebApp.MainButton.setText(t('premium_subscription.payButtonCaption'));
        Telegram.WebApp.MainButton.show();
        Telegram.WebApp.MainButton.onClick(handlePay);

        return () => {
            Telegram.WebApp.MainButton.hide();
        };
    }, [handlePay]);

    const handleClose = useCallback(() => {
        setShowModal(false);

        postCastService.event({
            eventName: 'user_close_paywall'
        });
    }, []);

    return (
        <Modal
            open={showModal}
            onOpenChange={handleClose}
            preventScrollRestoration
        >
            <UI.Wrapper>
                <UI.Head>
                    <UI.Close onClick={handleClose}>
                        <Tgico
                            inline
                            type='cross'
                            size={2}
                        />
                    </UI.Close>
                </UI.Head>

                <UI.Offer>
                    <UI.OfferTitle>
                        {t('premium_subscription.offer_title')}

                        <Tgico
                            inline
                            type='star'
                            size={1.5}
                        />
                    </UI.OfferTitle>

                    <UI.OfferDescription>
                        {t('premium_subscription.offer_description', { count: Math.round(durationSec / 60 / 60 / 24 / 30) } )}
                    </UI.OfferDescription>

                    <UI.PriceWrapper>
                        {
                            t('premium_subscription.offer_price', {
                                price,
                                months: Math.round(durationSec / 60 / 60 / 24 / 30)
                            })
                        }
                    </UI.PriceWrapper>

                    <UI.FeaturesDescription>
                        {t('premium_subscription.features_description')}
                    </UI.FeaturesDescription>

                    <UI.FeatureList>
                        <UI.FeatureItem>
                            <svg
                                width='18'
                                height='20'
                                viewBox='0 0 18 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M15.2451 5.99993C16.036 7.13376 16.4998 8.51268 16.4998 9.99993C16.4998 11.4872 16.036 12.8661 15.2451 13.9999M9.13432 2.36561L5.96863 5.5313C5.79568 5.70425 5.7092 5.79073 5.60828 5.85257C5.51881 5.9074 5.42127 5.9478 5.31923 5.9723C5.20414 5.99993 5.08185 5.99993 4.83726 5.99993H3.1C2.53995 5.99993 2.25992 5.99993 2.04601 6.10892C1.85785 6.20479 1.70487 6.35777 1.60899 6.54594C1.5 6.75985 1.5 7.03987 1.5 7.59993V12.3999C1.5 12.96 1.5 13.24 1.60899 13.4539C1.70487 13.6421 1.85785 13.7951 2.04601 13.8909C2.25992 13.9999 2.53995 13.9999 3.1 13.9999H4.83726C5.08185 13.9999 5.20414 13.9999 5.31923 14.0276C5.42127 14.0521 5.51881 14.0925 5.60828 14.1473C5.7092 14.2091 5.79568 14.2956 5.96863 14.4686L9.13431 17.6342C9.56269 18.0626 9.77688 18.2768 9.96077 18.2913C10.1203 18.3038 10.2763 18.2392 10.3802 18.1175C10.5 17.9773 10.5 17.6744 10.5 17.0686V2.9313C10.5 2.32548 10.5 2.02257 10.3802 1.88231C10.2763 1.76061 10.1203 1.69602 9.96077 1.70858C9.77688 1.72305 9.56269 1.93724 9.13432 2.36561Z'
                                    stroke='#037EE5'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                            </svg>

                            {t('premium_subscription.features.unlimited_listening', { count: maxSecPerDayLimit / 60 })}
                        </UI.FeatureItem>

                        <UI.FeatureItem>
                            <svg
                                width='22'
                                height='22'
                                viewBox='0 0 22 22'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M3.5 21V16M3.5 6V1M1 3.5H6M1 18.5H6M12 2L10.2658 6.50886C9.98381 7.24209 9.8428 7.60871 9.62353 7.91709C9.42919 8.1904 9.1904 8.42919 8.91709 8.62353C8.60871 8.84281 8.24209 8.98381 7.50886 9.26582L3 11L7.50886 12.7342C8.24209 13.0162 8.60871 13.1572 8.91709 13.3765C9.1904 13.5708 9.42919 13.8096 9.62353 14.0829C9.84281 14.3913 9.98381 14.7579 10.2658 15.4911L12 20L13.7342 15.4911C14.0162 14.7579 14.1572 14.3913 14.3765 14.0829C14.5708 13.8096 14.8096 13.5708 15.0829 13.3765C15.3913 13.1572 15.7579 13.0162 16.4911 12.7342L21 11L16.4911 9.26582C15.7579 8.98381 15.3913 8.8428 15.0829 8.62353C14.8096 8.42919 14.5708 8.1904 14.3765 7.91709C14.1572 7.60871 14.0162 7.24209 13.7342 6.50886L12 2Z'
                                    stroke='#037EE5'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                            </svg>


                            <span>
                                <Trans i18nKey='premium_subscription.features.celebrity_voices'>
                                    Voiced by celebrities or your own <UI.InProgress>In progress</UI.InProgress>
                                </Trans>
                            </span>
                        </UI.FeatureItem>

                        <UI.FeatureItem>
                            <svg
                                width='22'
                                height='20'
                                viewBox='0 0 22 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M12 5L10.8845 2.76892C10.5634 2.1268 10.4029 1.80573 10.1634 1.57116C9.95158 1.36373 9.69632 1.20597 9.41607 1.10931C9.09916 1 8.74021 1 8.02229 1H4.2C3.0799 1 2.51984 1 2.09202 1.21799C1.71569 1.40973 1.40973 1.71569 1.21799 2.09202C1 2.51984 1 3.0799 1 4.2V5M1 5H16.2C17.8802 5 18.7202 5 19.362 5.32698C19.9265 5.6146 20.3854 6.07354 20.673 6.63803C21 7.27976 21 8.11984 21 9.8V14.2C21 15.8802 21 16.7202 20.673 17.362C20.3854 17.9265 19.9265 18.3854 19.362 18.673C18.7202 19 17.8802 19 16.2 19H5.8C4.11984 19 3.27976 19 2.63803 18.673C2.07354 18.3854 1.6146 17.9265 1.32698 17.362C1 16.7202 1 15.8802 1 14.2V5Z'
                                    stroke='#037EE5'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                            </svg>

                            <span>
                                <Trans i18nKey='premium_subscription.features.create_folders'>
                                    Create your own folders <UI.InProgress>In progress</UI.InProgress>
                                </Trans>
                            </span>
                        </UI.FeatureItem>

                        <UI.FeatureItem>
                            <svg
                                width='22'
                                height='22'
                                viewBox='0 0 22 22'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M11 7V15M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z'
                                    stroke='#037EE5'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                            </svg>

                            <span>
                                <Trans i18nKey='premium_subscription.features.add_channels'>
                                    Add Telegram channels to AuchApp <UI.InProgress>In progress</UI.InProgress>
                                </Trans>
                            </span>
                        </UI.FeatureItem>

                        <UI.FeatureItem>
                            <svg
                                width='18'
                                height='20'
                                viewBox='0 0 18 20'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M17 10V11C17 15.4183 13.4183 19 9 19C4.58172 19 1 15.4183 1 11V10M9 15C6.79086 15 5 13.2091 5 11V5C5 2.79086 6.79086 1 9 1C11.2091 1 13 2.79086 13 5V11C13 13.2091 11.2091 15 9 15Z'
                                    stroke='#037EE5'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                            </svg>


                            <span>
                                <Trans i18nKey='premium_subscription.features.create_podcasts'>
                                    Share post-casts using your text <UI.InProgress>In progress</UI.InProgress>
                                </Trans>
                            </span>
                        </UI.FeatureItem>
                    </UI.FeatureList>
                </UI.Offer>

                <UI.Explanation>
                    {
                        t('premium_subscription.explanation', {
                            price,
                            strikedPrice,
                        })
                    }
                </UI.Explanation>
            </UI.Wrapper>
        </Modal>
    );
};

export default Paywall;
