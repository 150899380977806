import styled from 'styled-components';

import {
    TextBold16,
    TextMedium16,
    TextMedium20,
    TextRegular14
} from '@/shared/typography';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: #f7f7f7;
  padding: 0 12px;
  color: ${props => props.theme.colors.textPrimary};
`;

export const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 12px 0 20px;
  padding: 0 12px;
  width: 100%;
`;

export const Offer = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 24px 12px;
  margin-bottom: 26px;
`;

export const Explanation = styled.span`
  ${TextRegular14};
  color: ${props => props.theme.colors.textSecondary};
  text-align: center;
  margin-bottom: 60px;
  width: 75%;
`;

export const OfferTitle = styled.div`
  ${TextMedium20};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 8px;
  color: ${props => props.theme.colors.primaryButtonsLinks};
`;

export const OfferDescription = styled.div`
  ${TextRegular14};
  color: ${props => props.theme.colors.textSecondary};
  margin-bottom: 20px;
  text-align: center;
`;

export const FeaturesDescription = styled.span`
  ${TextMedium16};
`;

export const Close = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`;

export const FeatureList = styled.ul`
  list-style-type: none;
  
  padding: 0 12px;
`;

export const FeatureItem = styled.li`
  ${TextRegular14};
  
  display: inline-flex;
  align-items: center;
  margin: 10px 0;
  text-align: left;
  gap: 12px;
`;

export const PriceWrapper = styled.div`
  ${TextMedium20};
  display: flex;
  justify-content: center;
  color: ${props => props.theme.colors.textPrimary};
  margin-bottom: 36px;
`;

export const PlanText = styled.div`
  ${TextBold16};
`;

export const Price = styled.div`
  ${TextBold16}; 
`;

export const InProgress = styled.span`
  color: #838383; 
`;
