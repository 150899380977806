import { Spinner } from '@telegram-apps/telegram-ui';
import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Time from '@/lib/time';
import { Post } from '@/services/post-cast-service';

import * as UI from './ui';

import { getAnyDuration } from '../../../../domain/voice';


type ChannelPageProps = {
    displayedPlaylist: string;
    posts: Post[];
    currentPost: number;
    currentTime: number;
    playing: boolean;
    playerPlaylist: string;
    loading: boolean;
    onPostClick: (playlist: string, id: number) => void;
    onBack?: VoidFunction;
    onPlaylistLoad: (username: string) => void;
}

const ChannelPage: FC<ChannelPageProps> = ({
    displayedPlaylist,
    posts,
    currentPost,
    currentTime,
    playing,
    playerPlaylist,
    loading,
    onPostClick,
    onBack,
    onPlaylistLoad,
}) => {
    const { t } = useTranslation();

    const fetchBar = useRef<HTMLDivElement>(null);
    const currentPlayingRef = useRef<HTMLDivElement>(null);

    const isCurrent = playerPlaylist === displayedPlaylist;
    const isPlaying = playing && isCurrent;

    useEffect(() => {
        if (currentPlayingRef.current) {
            currentPlayingRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest'
            });
        }
    }, [currentPost]);

    useEffect(() => {
        Telegram.WebApp.BackButton.show();

        if (currentPlayingRef.current) {
            currentPlayingRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest'
            });
        }
    }, [displayedPlaylist]);

    // надо научиться делать пагинацию листая в обратную сторону - наверх
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        onPlaylistLoad(displayedPlaylist);
                    }
                });
            },
            {
                root: null,
                threshold: 0.1,
            },
        );

        if (fetchBar.current) {
            observer.observe(fetchBar.current);
        }

        return () => {
            if (fetchBar.current) {
                observer.unobserve(fetchBar.current);
            }
        };
    }, [fetchBar.current]);

    return (
        <UI.Wrapper>
            {/* eslint-disable-next-line i18next/no-literal-string */}
            {onBack && (<UI.BackButton onClick={onBack}>back</UI.BackButton>)}

            <UI.Heading>

                <UI.Title>
                    <UI.Title>{t('postcasts.title')}</UI.Title>
                </UI.Title>

                <UI.Sort>
                    {t('postcasts.sort')}

                    <svg
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path
                            // eslint-disable-next-line max-len
                            d='M14.1667 3.33331V16.6666M14.1667 16.6666L10.8333 13.3333M14.1667 16.6666L17.5 13.3333M5.83333 16.6666V3.33331M5.83333 3.33331L2.5 6.66665M5.83333 3.33331L9.16667 6.66665'
                            stroke='#037EE5'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                    </svg>

                </UI.Sort>
            </UI.Heading>

            {
                posts.map((post, index) => (
                    <React.Fragment key={post.title}>
                        <UI.Card
                            ref={isCurrent && index === currentPost ? currentPlayingRef : undefined}
                            $opacity={post.novoice}
                            onClick={() => onPostClick(displayedPlaylist, post.id)}
                        >
                            <UI.CardTitle>
                                <div>
                                    {new Date(post.createdAtOrigin * 1000).toDateString()}
                                </div>

                                <div>
                                    #{post.id}
                                </div>
                            </UI.CardTitle>

                            <UI.CardContent>
                                {post.content.slice(0, 150)}...
                            </UI.CardContent>

                            <UI.CardControl>

                                <UI.PlayWrapper selected={isCurrent && index === currentPost}>
                                    {
                                        isPlaying && index === currentPost ?
                                            <svg
                                                width='16'
                                                height='17'
                                                viewBox='0 0 16 17'
                                                fill='none'
                                                xmlns='http://www.w3.org/2000/svg'
                                            >
                                                <rect
                                                    x='1.90477'
                                                    y='1.64282'
                                                    width='4.57143'
                                                    height='13.7143'
                                                    rx='2'
                                                    fill='white'
                                                />

                                                <rect
                                                    x='9.5238'
                                                    y='1.64282'
                                                    width='4.57143'
                                                    height='13.7143'
                                                    rx='2'
                                                    fill='white'
                                                />
                                            </svg> :
                                            <svg
                                                width='13'
                                                height='15'
                                                viewBox='0 0 13 15'
                                                fill='none'
                                                xmlns='http://www.w3.org/2000/svg'
                                            >
                                                <path
                                                    // eslint-disable-next-line max-len
                                                    d='M0.370871 0.762038V13.9197C0.370871 13.9728 0.36341 14.3148 0.405631 14.3928C0.585008 14.7239 1.10424 15.1641 2.03006 14.719C2.95885 14.2726 8.77392 10.7832 12.1318 8.75218C12.557 8.49502 12.8148 8.03954 12.8148 7.54839C12.8148 7.04941 12.549 6.5881 12.1133 6.3329C8.75258 4.36438 2.95714 0.957442 2.03006 0.363267C1.03455 -0.274767 0.509137 0.363267 0.370871 0.762038Z'
                                                    fill={isCurrent && index === currentPost ? '#ffffff' : '#037EE5'}
                                                />
                                            </svg>

                                    }

                                    {
                                        isCurrent && index === currentPost ?
                                            Time.displayFormat(currentTime) :
                                            Time.displayFormat(getAnyDuration(post.audio))
                                    }

                                    {
                                        isCurrent && index === currentPost &&
                                            <>
                                                /{Time.displayFormat(getAnyDuration(post.audio))}
                                            </>
                                    }
                                </UI.PlayWrapper>
                            </UI.CardControl>
                        </UI.Card>
                    </React.Fragment>
                ))
            }

            <UI.FetchBar ref={fetchBar} />

            {
                loading && (
                    <UI.SpinnerWrapper $stretch={posts.length === 0}>
                        <Spinner size='m' />
                    </UI.SpinnerWrapper>
                )
            }
        </UI.Wrapper>
    );
};

export default ChannelPage;
