import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { currentTargetValue } from '@/lib/forms/selectors';

import * as UI from './ui';

type SearchProps = {
    value: string;
    onFocus: VoidFunction;
    onChange: (value: string) => void;
    onCancel?: VoidFunction;
}
const Search: FC<SearchProps> = ({
    value,
    onFocus,
    onChange,
    onCancel,
}) => {
    const { t } = useTranslation();

    return (
        <UI.Wrapper>
            <UI.Input
                value={value}
                type='text'
                placeholder='Search'
                onFocus={onFocus}
                onChange={currentTargetValue(onChange)}
            />

            {
                onCancel && (
                    <UI.Cancel onClick={onCancel}>{t('search.cancel')}</UI.Cancel>
                )
            }
        </UI.Wrapper>
    );
};

export default Search;
