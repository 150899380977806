import { css } from 'styled-components';

const FontSmooth = css`
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
`;

export const Headline15pxMedium = css`
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;


  ${FontSmooth};
`;

export const Headline16pxMedium = css`
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;


  ${FontSmooth};
`;
