import { AppRoot } from '@telegram-apps/telegram-ui';
import { createRoot } from 'react-dom/client';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

import StorageService from '@/services/storage-service';
import { defaultTheme } from '@/shared/themes';

import initializeI18N from './i18n';
import ConfigurationService from './services/configuration-service';
import LogsService from './services/logs-service';
import AppLayout from './shared/ui/layout/app-layout';
import App from './singlepage/app';

import './shared/globals.css';
import '@telegram-apps/telegram-ui/dist/styles.css';

class ReactAppRoot {
    private logsService: LogsService;
    private configurationService: ConfigurationService;
    private storageService: StorageService;

    constructor(
        logsService: LogsService,
        configurationService: ConfigurationService,
        storageService: StorageService,
    ) {
        this.logsService = logsService;
        this.configurationService = configurationService;
        this.storageService = storageService;
    }

    public initializeI18n = initializeI18N;

    private renderMainRoot = () => {
        return (
            <>
                <StyleSheetManager>
                    <AppRoot className='appRoot'>
                        <ThemeProvider theme={defaultTheme}>
                            <AppLayout>
                                <App
                                    logsService={this.logsService}
                                    configurationService={this.configurationService}
                                    storageService={this.storageService}
                                />
                            </AppLayout>
                        </ThemeProvider>
                    </AppRoot>
                </StyleSheetManager>
            </>
        );
    };

    public render() {
        let appNode = window.document.querySelector<Element>('#app');
        if (appNode === null) {
            this.logsService.write('app does not exist');

            appNode = window.document.createElement('div');
            appNode.id = 'app';
            document.body.appendChild(appNode);
        }

        createRoot(appNode).render(this.renderMainRoot());
    }
}

const main = () => {
    const logsService = new LogsService();
    logsService.writeInitialLogLine();

    const configurationService = new ConfigurationService();
    const storageService = new StorageService();

    const app = new ReactAppRoot(
        logsService,
        configurationService,
        storageService,
    );
    app.initializeI18n();

    app.render();
};

main();
