import styled, { css } from 'styled-components';

import { TextMedium16, TextRegular14 } from '@/shared/typography';

export const Wrapper = styled.div<{ $flat?: boolean; $hovered?: boolean }>`
  padding: 0 12px;
  ${props => props.$flat && css`
    padding: 0;
  `};

  ${props => props.$hovered && css`
    background: ${props.theme.colors.backgroundChannelPage};
  `};

  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  height: 72px;
  overflow: hidden;
  cursor: pointer;
`;

export const Left = styled.div`
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Content = styled.div<{ $center: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-inline-start: 12px;
  white-space: nowrap;
  padding: 14px 0;
  text-overflow: ellipsis; //  todo dubicate
  overflow: hidden;

  ${props => props.$center && css`
    justify-content: center;
  `}
`;

export const Head = styled.span`
  ${TextMedium16};

  text-overflow: ellipsis; //  todo dubicate
  overflow: hidden;
  color: ${props => props.theme.colors.textPrimary};
`;

export const Body = styled.span`
  ${TextRegular14};

  margin-top: .125rem;
  white-space: nowrap;
  color: ${props => props.theme.colors.textSecondary};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Before = styled.div`
  display: flex;
  align-items: center;
`;

export const After = styled.div`
  display: flex;
  align-items: center;
`;

