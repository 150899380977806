import styled from 'styled-components';

import { TextRegular14 } from '@/shared/typography';
export const Wrapper = styled.div`
  padding: 12px;
  display: flex;
`;

export const Input = styled.input`
  ${TextRegular14};

  height: 32px;
  width: 100%;
  background: ${props => props.theme.colors.searchBar};
  color: ${props => props.theme.colors.textPrimary};

  &::placeholder {
    color: ${props => props.theme.colors.textPrimary};
  }

  border-radius: 8px;
  border: 0;
  outline: 0;
  text-align: center;
  padding: 0 6px;
  
  &:focus {
    text-align: left;
  }
`;


export const Cancel = styled.div`
  padding: 8px 12px;
  color: ${props => props.theme.colors.primaryButtonsLinks};
`;
