import { css } from 'styled-components';

export const FontSmooth = css`
    font-family: var(--tgui--font-family);
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
`;

// const FontBold = css`
//   font-weight: 600;;
// `;
//
// const FontMedium = css`
//   font-weight: 500;;
// `;

export const TextMedium20 = css`
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;

    ${FontSmooth};
`;

export const TextBold16 = css`
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;

    ${FontSmooth};
`;

export const TextMedium16 = css`
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;

    ${FontSmooth};
`;

export const TextMedium15 = css`
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;

    ${FontSmooth};
`;

export const TextRegular14 = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  ${FontSmooth};
`;
