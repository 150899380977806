import styled from 'styled-components';

export const ModalContent = styled.div`
  height: 100vh;
  max-height: 100vh;
  padding: 0 24px 66px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  align-items: center;
`;

export const SwipeDown = styled.div`
`;

export const Title = styled.div`
`;

export const ShareLink = styled.div`
`;


export const PlayerCover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
`;

export const Image = styled.img`
  max-height: 40vh;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
`;

export const PlayerContent = styled.div`
  margin-bottom: 24px;
`;

export const AdditionalControl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const PlaybackRate = styled.div`
  cursor: pointer;
  color: ${props => props.theme.colors.primaryButtonsLinks};
  user-select: none;
  display: flex;
  font-size: 1.5rem;
  align-items: center;
`;

export const ContentText = styled.p`
  white-space: break-spaces;
`;
