import styled, { css } from 'styled-components';

import { TextMedium15 } from '@/shared/typography';
import { Headline15pxMedium } from '@/shared/typography/text';

export const Wrapper = styled.div`
  width: 100%;
  height: 38px;
  overflow-y: hidden;
`;

export const TabsList = styled.div`
  display: flex;
  overflow-x: scroll;
  padding-bottom: 17px;
  box-sizing: content-box;
`;

export const TabsItem = styled.div<{ selected: boolean }>`
  ${TextMedium15};
  white-space: nowrap;
  color: ${props => props.theme.colors.textPrimary};

  padding: 8px 12px 10px 12px;
  
  ${props => props.selected && css`
      color: ${props.theme.colors.primaryButtonsLinks};
      border-bottom: 2px solid ${props.theme.colors.primaryButtonsLinks};
    ;
  `}
  
  ${Headline15pxMedium};
`;
