import { FC } from 'react';

import * as UI from './ui';

type TabsProps = {
    selected: string;
    tabs: { slug: string, label: string }[];
    onClick: (slug: string) => void;
}

const Tabs: FC<TabsProps> = ({ selected, tabs, onClick }) => {
    return (
        <UI.Wrapper>
            <UI.TabsList>
                {
                    tabs.map(({ slug, label }) => (
                        <UI.TabsItem
                            key={slug}
                            selected={slug === selected}
                            onClick={() => onClick(slug)}
                        >
                            {label}
                        </UI.TabsItem>
                    ))
                }
            </UI.TabsList>
        </UI.Wrapper>
    );
};

export default Tabs;
