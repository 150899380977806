import { FC } from 'react';

import * as UI from './ui';

type CellProps = {
    flat?: boolean;
    hovered?: boolean;
    before?: React.ReactElement;
    head?: React.ReactElement | string;
    body?: React.ReactElement | string;
    content?: React.ReactElement;
    after?: React.ReactElement;
    onClick?: VoidFunction;
}

const Cell: FC<CellProps> = ({
    hovered,
    flat,
    before,
    head,
    body,
    after,
    onClick,
}) => {
    return (
        <UI.Wrapper
            onClick={onClick}
            $flat={flat}
            $hovered={hovered}
        >
            <UI.Left>
                <UI.Before>
                    { before }
                </UI.Before>

                <UI.Content $center={!body}>
                    <UI.Head>
                        {head}
                    </UI.Head>

                    {
                        body ? (
                            <UI.Body>
                                {body}
                            </UI.Body>

                        ) : null
                    }
                </UI.Content>
            </UI.Left>

            <UI.After>
                { after }
            </UI.After>
        </UI.Wrapper>
    );
};

export default Cell;
