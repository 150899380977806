import styled, { css } from 'styled-components';

import {
    Headline15pxMedium,
    Headline16pxMedium
} from '@/shared/typography/text';

export const BackButton = styled.div`
  position: fixed;
  left: 0;
  top: 0;
`;

export const Wrapper = styled.div`
  padding: 12px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 13px 0;
`;


export const Title = styled.div`
  ${Headline16pxMedium};

  color: ${props => props.theme.colors.textPrimary};
`;

export const Sort = styled.div`
  color: ${props => props.theme.colors.textPrimary};
  display: flex;
  align-items: center;
  ${Headline16pxMedium};
  
  opacity: .3;
`;

export const Card = styled.div<{ $opacity: boolean }>`
  background-color: #ffffff;
  padding: 16px 12px;
  background: #FFFFFF;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 10px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-bottom: 12px;
  
  cursor: pointer;
  
  ${props => props.$opacity && css`
    opacity: .3;
  `}
`;

export const CardTitle = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  color: ${props => props.theme.colors.textPrimary};
  
  ${Headline15pxMedium};
`;

export const CardContent = styled.div`
  color: ${props => props.theme.colors.textPrimary};
  margin-bottom: 10px;
`;

export const CardControl = styled.div`

`;

export const PlayWrapper = styled.div<{ selected: boolean }>`
    padding: 6px 12px;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    background: #D5E9FF;
    border-radius: 10px;
  
    ${Headline16pxMedium};
    color: ${props => props.theme.colors.primaryButtonsLinks};
  
  ${props => props.selected && css`
    color: #ffffff;
    background: ${props.theme.colors.primaryButtonsLinks};
  `}
`;

export const FetchBar = styled.div`
  width: 100%;
`;

export const SpinnerWrapper = styled.div<{ $stretch: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  
  ${props => props.$stretch && css`
    flex: 1;
    align-items: center;
  `}
`;
