import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ $withSubtitile: boolean }>`
  position: relative;
  padding-top: 94px;
  
  ${props => props.$withSubtitile && css`
    padding-top: 126px;
  `}
`;

export const Head = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${props => props.theme.colors.backgroundHeader};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 10px rgba(0, 0, 0, 0.04);
`;

export const Subtitle = styled.div`
  padding: 8px 12px;
  color: ${props => props.theme.colors.primaryButtonsLinks};
`;

export const AvatarWrapper = styled.div`
  position: relative;
`;

export const PlayinBadge = styled.div`
  position: absolute;
  right: -8px;
  bottom: -8px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding-bottom: 64px;
`;

export const Underline = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.iconsBorders};
  margin: 0 12px 0 72px;
`;

export const More = styled.div`
  padding-top: 24px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const FetchBar = styled.div`
  width: 100%;
`;
