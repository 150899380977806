class NavigatorService {
    static LANGUAGE_PARAM = 'lng';


    public getMiniAppStartupParam = (): [string | undefined, number | undefined, string | undefined, string | undefined] | null => {
        const params = new URLSearchParams(window.Telegram?.WebApp.initData);

        const param = params.get('start_param');
        if (!param) {
            return null;
        }

        const [playlistValue, postToPlayValue, utmValue, channelsValue] = param.split('-');

        const playlist = playlistValue ? playlistValue : undefined;
        const postToPlay = postToPlayValue ? postToPlayValue : undefined;
        const utm = utmValue ? utmValue : undefined;
        const channels = channelsValue ? channelsValue : undefined;

        if (postToPlay) {
            return [playlist, parseInt(postToPlay, 10 || '0'), utm, channelsValue];
        }

        return [playlist, undefined, utm, channels];
    };
}

export default NavigatorService;
